<template>
   <div class="grid">
      <Toast />
      <ConfirmDialog></ConfirmDialog>
      <div class="col-12">
         <div class="card">
            <Toolbar class="mb-4">
               <template #start>
                  <Button
                     label="Add"
                     icon="pi pi-plus"
                     class="p-button-success mr-2 p-button-sm"
                     @click="addDoctor()"
                  />
               </template>
               <template #end>
                  <div>
                     <Button
                        label="All"
                        class="p-button-text p-button-info p-button-sm"
                     />
                     <Button
                        label="Approved"
                        class="p-button-text p-button-success p-button-sm"
                     />
                     <Button
                        label="Pending"
                        class="p-button-text p-button-warning p-button-sm"
                     />
                     <Button
                        label="Reject"
                        class="p-button-text p-button-danger p-button-sm"
                     />
                     <Button
                        label="Trashed"
                        class="p-button-text p-button-secondary p-button-sm"
                     />
                  </div>
               </template>
            </Toolbar>
            <div>
               <DataTable
                  ref="dt"
                  :value="doctors"
                  dataKey="id"
                  :paginator="true"
                  :rows="10"
                  :filters="filters"
                  :loading="loading"
                  responsiveLayout="scroll"
               >
                  <template #header>
                     <div
                        class="
                           table-header
                           p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                        "
                     >
                        <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                           Manage doctors
                        </h5>
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters['global'].value"
                              placeholder="Search..."
                           />
                        </span>
                     </div>
                  </template>

                  <Column
                     field="id"
                     header="ID"
                     style="min-width: 2rem"
                  ></Column>
                  <Column
                     field="name"
                     header="Name"
                     style="min-width: 8rem"
                  ></Column>
                  <Column
                     field="email"
                     header="Email"
                     style="min-width: 11rem"
                  ></Column>
                  <Column
                     field="phone"
                     header="Phone"
                     style="min-width: 7rem"
                  ></Column>
                  <Column
                     field="current_balence"
                     header="Current Balance"
                     style="min-width: 6rem"
                  ></Column>
                  <Column
                     field="doctor_type"
                     header="Doctor Type"
                     style="min-width: 7rem"
                  ></Column>
                  <Column
                     field="status"
                     header="Status"
                     style="min-width: 7rem"
                  ></Column>
                  <Column
                     :exportable="false"
                     style="min-width: 10rem"
                     header="Action"
                  >
                     <template #body="{ data }">
                        <Button
                           icon="pi pi-eye"
                           class="p-button-rounded p-button-info p-mr-2"
                           @click="showDetail(data.id)"
                        />
                        <Button
                           icon="pi pi-pencil"
                           class="p-button-rounded p-button-success p-mr-2"
                        />
                        <Button
                           icon="pi pi-trash"
                           class="p-button-rounded p-button-warning"
                           @click="deleteDoctor(data.id)"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";

export default {
   data() {
      return {
         doctors: null,
         filters: {},
         loading: true,
      };
   },
   methods: {
      ...mapActions(["doctor/fetchAllDoctors", "doctor/delete"]),
      fethAllDoctors() {
         this["doctor/fetchAllDoctors"]()
            .then((response) => {
               this.doctors = response;
               this.loading = false;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      initFilters() {
         this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
         };
      },
      showDetail(id) {
         this.$router.push({ name: "doctorDetail", params: { id: id } });
      },
      addDoctor() {
         this.$router.push({ name: "doctorCreate" });
      },

      deleteDoctor(id) {
         this.$confirm.require({
            message: "Are you sure you want to proceed?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "Successfully deleted.",
                  life: 3000,
               });
               this["doctor/delete"](id)
                  .then(() => {
                     this.loading = true;
                     this.fethAllDoctors();
                     console.log(id);
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "You have rejected",
                  life: 3000,
               });
            },
         });
      },
   },
   created() {
      this.fethAllDoctors();
      this.initFilters();
   },
};
</script>

<style lang="scss" scoped>
.table-header {
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media screen and (max-width: 960px) {
      align-items: start;
   }
}

.p-button {
   margin-right: 0.5rem;
}
</style>
